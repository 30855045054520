import React from 'react'
import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  system,
} from "styled-system";

const Button = styled.button.attrs(props => ({
  type: props.type ? props.type : 'submit',
}))`
${space};
${color};
${typography};
${layout};
${flexbox};
${grid};
${background};
${border};
${position};
${shadow};

padding : ${props => props.p ? props.p : "6px 12px"};
border : ${props => props.border ? props.border : "1px solid #ff8800"};
border-radius : ${props => props.borderRadius ? props.borderRadius : "8px"};
font-size :  ${props => props.fontSize ? props.fontSize : "13px"};
font-weight : ${props => props.fontWeight ? props.fonWeight : "500"};
width : ${props => props.width ? props.width : "100%"};
background-color :  ${props => props.bg ? props.bg : "#ff8800"};
color : ${props => props.color ? props.color : "white"};
outline : none;
    
`

export default Button