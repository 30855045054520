import React from 'react'
import * as d3 from "d3"
import { useEffect, useRef } from 'react';


function ConnectorResistance({padding = 0, bgColor = "white", projectionData, filters }) {

  const wrapper = useRef(null)
  useEffect(() => {
    const dateRange = filters.end_date.diff(filters.start_date, "days") + 2;
    const height = wrapper.current.clientHeight
    const width = wrapper.current.clientWidth

    // let data = details.map((item, index) => {
    //     if(index%100 === 0){
    //       return item
    //     }
    // })

    const data = projectionData.filter(item => item !== undefined)
    data.sort((a,b) => new Date(a._id) - new Date(b._id))
    const xDate = data.map(item => new Date(item._id))
    const yResis = data.map(item => item.average)
    const combinedData = data.map(item => {
      return {
        x : new Date(item._id),
        y : item?.average 
      }
    })

    console.log("data", data, xDate, yResis)
    const resisMax = yResis.sort((a,b) => b-a)[0]
    const getTickInterval = () => {
    console.log("date range", dateRange)

      if(xDate.length < 3){
        return d3.timeDay.every(1)
      }

      return  dateRange <= 60 ? dateRange <= 31 
              ? dateRange <= 8 
              ? d3.timeDay.every(1) : 
              d3.timeDay.every(3) : 
              d3.timeWeek.every(1) :
              d3.timeWeek.every(2)
    }

    const spacing = 50
    const elevatexAxis = 20
    const svg = d3.select(".line_chart").append("svg").attr("width", "100%").attr("height", "100%").style("background-color", bgColor).style("padding", padding)
     
    const defs = svg.append("defs");

    const gradient = defs.append("linearGradient")
    .attr("id", "svgGradient")
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "0%")
    .attr("y2", "0%");

    gradient
      .selectAll("stop")
        .data([
          {offset: "0%", color: "#eff4f6"},
          {offset: "100%", color: "#558194"}
        ])
      .enter().append("stop")
        .attr("offset", function(d) { return d.offset; })
        .attr("stop-color", function(d) { return d.color; });
    const xScale = d3.scaleTime()
                          .domain([d3.min(xDate), d3.max(xDate)])
                          .range([spacing, width - spacing])


    const yScale = d3.scaleLinear()
                    .domain([0, resisMax])
                    .range([height - spacing, elevatexAxis])

    const xAxis = d3.axisBottom(xScale).ticks(getTickInterval()).tickFormat((d) => String(d).slice(4,15))
    svg.append("g").classed("axis", true).attr("transform", `translate(0,${height-elevatexAxis})`).call(xAxis)
    const yAxis = d3.axisLeft(yScale).ticks(8)
    svg.append("g").classed("axis", true).attr("transform", `translate(${spacing},${spacing-elevatexAxis})`).call(yAxis)

    const line1 = d3.line().x(d => xScale(d.x)).y(d => yScale(d.y) + spacing - elevatexAxis).curve(d3.curveBasis)

    const path = svg.append("path").attr("transform", `translate(1,0)`).attr("d", line1(combinedData)).style("fill", "none").style("stroke", "url(#svgGradient)").style("stroke-width",5)
    const pathLength = path.node().getTotalLength();
    path
    .attr("stroke-dashoffset", pathLength)
    .attr("stroke-dasharray", pathLength)
    .transition()
    .duration(2500)
    .attr("stroke-dashoffset", 0);

    // //remove axis lines and ticks
    // d3.selectAll(".axis path").remove()
    // d3.selectAll(".axis g line").attr("stroke", "#c6c9cb")

    
  },[filters])

  return (
    <div ref={wrapper} style={{height : "100%", width : "100%"}} className="line_chart"></div>
  )
}

export default ConnectorResistance