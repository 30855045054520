import React from 'react'
import * as d3 from "d3"
import { useEffect } from 'react';
import { feature } from 'topojson';
import { useRef } from 'react';
import "./charts.css"

const places = [
  {
    name: "Switzerland",
    location: {
      latitude: 46.8182, 
      longitude: 8.2275,
    },
    color : "#28A5DB",
    value : 4
  },
  {
    name: "Delhi, India",
    location: {
      latitude: 28.7041, 
      longitude : 77.1025
    },
    color : "#6F87DB",
    value : 5
  },
]

function WorldMap({locations = places}) {

  const wrapper = useRef(null)

  const getScaleValue = (width) => {
    if(width > 690){
      return 72
    } 
    if(width > 590){
      return 69
    }
    if(width > 500){
      return 60
    }

    return 55
  }

  useEffect(() => {
    console.log(wrapper)
    let width = wrapper.current.clientWidth
    let height = wrapper.current.clientHeight
    let svg = d3.select(".world-map").append("svg")
    .attr("height", "100%").attr("width", "100%").style("background-color", "white").style("position", "relative")

    const projection = d3.geoMercator()
    .scale(getScaleValue(width))
    .translate([width / 2, height / 1.5]);
    const path = d3.geoPath(projection);

    const g = svg.append('g').attr("transform", "translate(0, 10)");

    d3.json('https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json')
        .then(data => {
            
            const countries = feature(data, data.objects.countries);
            g.selectAll('path')
            .data(countries.features).enter().append('path')
            .attr('class', 'country')
            .attr('d', path);

        });
      
    svg.selectAll(".pin")
    .data(locations)
    .enter().append("circle", ".pin")
    .attr("r", 3)
    .style("cursor", "pointer")
    .style("fill", (d) => d.color)
    .attr("transform", d => 
    `translate(${projection([
        d?._id?.longitude,
        d?._id?.latitude
      ])
    })`
    )
    .on("mouseenter", (e, d) => {
      let x0 = d3.pointer(e)[0];
      let y0 = d3.pointer(e)[1];
      
      console.log(x0, y0)
      d3.select(".hoverDiv")
      .style("left", x0 + 430 + "px")
      .style("top", y0 +220+ "px")
      .style("opacity", 1)
      .html(`<p class="remove-bottom-margin">Location : ${d?._id?.site_address}</p>
             <p class="remove-bottom-margin">No. of Connectors : ${d?.count}</p>
      `)
    })
    .on("mouseout", () => {
      d3.select(".hoverDiv")
      .style("opacity", 0)
    })
    ;
  },[])

  return (
    <div ref={wrapper} style={{height : "100%", width : "100%", position:"relative"}} className="world-map">
       <div className="hoverDiv" style={{zIndex:1}}>
       </div> 
    </div>
  )
}

export default WorldMap