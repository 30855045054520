import fetch from 'isomorphic-fetch';

const PROTOCOL = window.location.protocol;
const HOST = window.location.host;
const API_URL = `${process.env.REACT_APP_API_URL}`;

export default function downloadApi(name, endpoint, method = 'get', body) {
    return fetch(`${endpoint}`, {
        headers: {'content-type': 'application/json'},
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.blob()).then(
            blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = name ? name : "venue-leads.csv";
                a.click();
            });
}
