import callApi, { LambdaCallApi } from "../../util/apiCaller";
import { errorMsg, successMsg } from "util/toast";
import moment from "moment";

export const SET_CONNECTOR_LIST = "SET_CONNECTOR_LIST"
export const SET_CONNECTOR_LIST_LOADING = "SET_CONNECTOR_LIST_LOADING"
export const SET_CONNECTOR_LIST_LOADED = "SET_CONNECTOR_LIST_LOADED"

export const setConnectorList = (data) => ({
  type : SET_CONNECTOR_LIST,
  data
})

export const setIsConnectorListLoading = (status) => ({
  type : SET_CONNECTOR_LIST_LOADING,
  status
})

export const setIsConnectorListLoaded = (status) => ({
  type : SET_CONNECTOR_LIST_LOADED,
  status
})

export function getConnectorList() {

  return async (dispatch) => {
    try {
      dispatch(setIsConnectorListLoading(true));
      const res = await callApi(`api/results/connectors/list`, "post", {
          "pageNum" : 1,
          "pageSize" : 100
      });

      if (res && res.status === "Success") {
        dispatch(setConnectorList(res?.data?.connectors));
        dispatch(setIsConnectorListLoaded(true));
        dispatch(setIsConnectorListLoading(false));

      } else {
        errorMsg("Connector list not loaded!");
      } 
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsConnectorListLoading(false));
    }
  };
}


