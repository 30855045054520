import React, { useEffect, useState, useRef } from 'react'
import * as d3 from "d3"
import { color, selectAll } from 'd3';
import { sankey } from 'd3-sankey';
import SankeyNode from './SankeyNode';
import SankeyLink from './SankeyLink';
import "./sankey.css"
import moment from "moment"

let date = moment()
let dummyData = {
  nodes : [
    {"name":"H1", "color" : "#F04B4B", value : 1, type : "Hazardous"}, 
    {"name":"F1", "color" : "#F2A541", value : 6, type : "Faulty"},
    {"name":"N1", "color" : "#60C610", value : 2, type : "Normal"},
    {"name":"H2", "color" : "#F04B4B", value : 3, type : "Hazardous"}, 
    {"name":"F2", "color" : "#F2A541", value : 5, type : "Faulty"}, 
    {"name":"N2", "color" : "#60C610", value : 1, type : "Normal"},
    ], 
  links : [
    {
      datetime : moment(date).subtract({ day: 0 }).format("DD-MM-YYYY"),
      transactions : [["H1","H2", 1, "#F04B4B"],["F1","H2",2, "#F2A541"], ["N1","N2",1, "#60C610"], ["N1","F2",1, "#60C610"],["F1","F2",4, "#F2A541"]]
    },
    {
    datetime : moment(date).add({ day: 365 }).format("DD-MM-YYYY"),
    transactions : []
    },
  ]}

let legends = [
  {grade : "Normal", class : "normal", number : 2, color : "#60c610"},
  {grade : "Faulty", class : "fieldAged", number : 2, color : "#F2A541"},
  {grade : "Hazardous", class : "hazardous", number : 2, color : "#F04B4B"},
  ]

function Sankey({data = dummyData}) {
  const wrapper = useRef(null);
  const [svgWidth, setSvgWidth] = useState(100)
  const [svgHeight, setSvgHeight] = useState(100)
  const [dateScale, setDateScale] = useState([])
  const [nodes, setNodes] = useState(null)
  const [links, setLinks] = useState(null)

  useEffect(() => {
    setSvgWidth(wrapper.current.clientWidth)
    setSvgHeight(wrapper.current.clientHeight)
  }, [])
  
  useEffect(() => {
    let {nodes, links} = sankey()
    .nodeId(d => d.name)
    // .nodeWidth(15)
    .nodePadding(40)
    .extent([[1, 1], [svgWidth , svgHeight - .1*svgHeight]])(buildData(data))

    setNodes(nodes)
    setLinks(links)

    let dates = Array.from(new Set(data.links?.map(item => item.datetime)))
    setDateScale(dates.sort((a,b) => a-b))

    for(let node of data.nodes){
      d3.select(`.${node.type.toLowerCase()}-${node.name}`)
      .on("mouseover", (e,d) => {

        let x0 = d3.pointer(e)[0];
        let y0 = d3.pointer(e)[1];
        
        console.log(x0, y0)
        d3.select(".hoverDiv")
        .style("left", x0 + 375 + "px")
        .style("top", y0 + 90 + "px")
        .style("opacity", 1)
        .html(`<p class="remove-bottom-margin">Type : ${node.type}</p>
               <p class="remove-bottom-margin">Value : ${node.value}</p>
        `)
      })
      .on("mouseout", () => {
        d3.select(".hoverDiv")
        .style("opacity", 0)
      })
    }
  },[svgWidth, svgHeight])

  const buildData = (data) => {
    let consolidatedData = {nodes : [], links : []}
    data.nodes?.map(item => {
      consolidatedData.nodes.push(item)
    })

    data.links?.map(item => {
      item.transactions?.map(link => {
        consolidatedData.links.push({source : link[0], target : link[1], value : link[2], color : link[3]})
      })
    })

    console.log(consolidatedData)
    return consolidatedData
  }


  console.log(nodes, links)

  return (
    <>
    <div style={{height : "100%", textAlign:"start"}}>
        <div className="d-flex border-bottom align-items-center pb-3">
          <img
            src="https://ik.imagekit.io/rzlzf40okya/smarthelio/Prediction-icon.svg"
            className="mr-2"
          />
          <h6 className="remove-bottom-margin" style={{ textAlign: "start", }}>Connector Status Prediction</h6>
        </div>

    {/* <hr className='remove-bottom-margin'/> */}
    <div style={{display:"flex", height : "90%", width : "100%", overflow: "hidden"}}>
         <div className="sankey" style={{height : "95%", display: "flex", flex: "0 0 82%", justifyContent : "center", flexDirection :"column", margin : "2%"}}>
            <svg ref={wrapper} style={{backgroundColor : "white", height : "100%", width : "100%", display:"flex", justifyContent:"center"}}>
                <g>
                  {nodes && nodes.map((node,i) => <SankeyNode key={i} {...node}/>)}
                </g>
                <g>
                  {links && links.map((link,i) => <SankeyLink key={i} link={link} svgWidth={svgWidth}/>)}
                </g>
            </svg>
          
          <div style={{display : "flex", alignItems:"center", marginBottom : "20px", justifyContent : "space-between"}}>
                {dateScale?.map(date => {
                    return <p className="remove-bottom-margin" key={date}>{date}</p>
                })}
          </div>
        </div>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <svg style={{width : "100%"}}>
              {legends.map((item,i) => {
                return <g style={{ transform : `translate(15%, ${(i)*50 + 20}px`}}>
                    <text style={{transform: "translate(35px, 12px)"}}>{item.grade}</text>
                    <rect style={{width : "24px", height : "15px", fill: item.color, rx :"4px"}}></rect>
                  </g>
              })}
            </svg>
        </div>

          <div className="hoverDiv">
          </div> 
        </div>
    </div>
      
    </>
    
  )
}

export default Sankey