import './ConnectorTable.css';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import DefaultModal from 'components/DefaultModal/DefaultModal';
import Input from 'konnect/Input/Input';
import PropTypes from 'prop-types';
import callApi from 'util/apiCaller';
import { errorMsg, successMsg } from 'util/toast';
import Tables from 'components/Tables/Tables';

const InputDiv = styled.div`

    margin-bottom : 5px;

    .error { 
        color : red;
        font-size: 12px
    }

    label {
        font-size : 10px;
        margin-bottom : 4px;
    }
`

const options = {
    chart: {
        height: 700,
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        width: 2,
        dashArray: 0,
    },
    title: {
        text: 'Projected Loss Graph',
        align: 'left'
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        title: {
            text: 'Date'
        }
    },
    yaxis: {
        title: {
            text: 'Cumulative Projected Loss (in CHF)'
        },
    },
};

const DeviceTable = ({ connectorDevices }) => {

    const columnsItems=[
        {field:'clients',header:'clients'},
        {field:'siteaddress',header:'site address'},
        {field:'deviceid',header:'device id'},
        {field:'devicename',header:'device name'},
        {field:'label',header:'label'},
        {field:'confidencelevel',header:'label'},   
    ]

    const rowsItems =[
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
        {clients:'Staubli',siteaddress:'New Delhi, India',deviceid:'8991102105457041545f',devicename:'MC4 1',label:'Normal',confidencelevel:'80'},
    ]

    const [newDevice, setnewDevice] = useState({
        client_name : "",
        device_name : "",
        address : "",
        device_label : ""
    })
    const [errors, setErrors] = useState({})
    const [addDeviceModal, setAddDeviceModal] = useState(false);
    const openAddDeviceModal = () => setAddDeviceModal(true);
    const closeAddDeviceModal = () => {
        setAddDeviceModal(false);
        setnewDevice({})
        setErrors({})

    };

    
    const handleChange = (keyName, value) => {
        let update = {...newDevice}
    
        update[keyName] = value
    
        setnewDevice(update)
    }

    const handleValidation = () => {

        let isValid = true;
        const newErrors = {};
        if (!newDevice.client_name) {
            isValid = false;
            newErrors.client_name = 'Please enter client name';
        }
        if (!newDevice.device_name) {
            isValid = false;
            newErrors.device_name = 'Please enter device name';
        }
        if (!newDevice.address) {
            isValid = false;
            newErrors.address = 'Please enter address';
        } 
        if (!newDevice.device_label) {
            isValid = false;
            newErrors.device_label = 'Please enter device label';
        }
        return {
            isValid,
            errors: newErrors
        }
    };

    const handleBtnClick = (type) => {

        if(type == "right"){
            const check = handleValidation();

            if(check.isValid){
                successMsg("Device added successfully!")
                setAddDeviceModal(false);
                setnewDevice({})
                setErrors({})

                //Todo : Add end point
                // callApi("", "post", {
                //     device : newDevice
                // })
                // .then(res => {
                //     if(res.status == "Success"){
                //         successMsg("Device added successfully!")
                //         //Todo : Add fecthDevices function call for rerender
                //         //currently devices are updated from JSON file
                //     }

                // })
                // .catch(err => {
                //     errorMsg("Something went wrong")
                //     console.log(err)
                // })
            } else {
                setErrors(check.errors)
            }    
        } else {
            setAddDeviceModal(false);
            setnewDevice({})
            setErrors({})
        }
       
    }

    return (
        <>
            <div className='connecter-box'>
                <p>Devices</p>
            </div>
            <div className='col-lg-12 reports-content'>
                <div className='row reports-content-row'>
                    {connectorDevices.length ? (
                        <div className='reports-content-box' style={{ textAlign: 'left' }}>
                            {/* <table className="table table-hover mobile-none">
                                <thead>
                                    <tr>
                                        <th scope="col" className='table-heading'>Clients</th>
                                        <th scope="col" className='table-heading'>Site Address
                                        </th>
                                        <th scope="col" className='table-heading'>{'Device ID'}</th>
                                        <th scope='col' className='table-heading'>
                                            Device Name
                                        </th>
                                        <th scope='col' className='table-heading'>
                                            Label
                                        </th>
                                        <th scope='col' className='table-heading'>
                                            Confidence Level
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {connectorDevices?.map((plant) => (
                                        <tr
                                            // style={{textAlign: 'center'}}
                                            key={plant.connector_name}
                                        >

                                            <td>
                                                {plant.client}
                                            </td>
                                            <td>
                                                {plant.site_address}
                                            </td>
                                            <td>
                                                {plant.device_id}
                                            </td>
                                            <td>
                                                {plant.connector_name}
                                            </td>
                                            <td>
                                                {plant.fault}
                                            </td>
                                            <td>
                                                {plant.confidence_interval}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> */}
                            <Tables columnsData={columnsItems} rowData={rowsItems}/>
                        </div>
                    ) : (
                        <div>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
            <div className='add-device-btn'>
                <button type="button" onClick={openAddDeviceModal}>ADD DEVICE</button>
            </div>
            <DefaultModal
                show={addDeviceModal}
                onHide={() => closeAddDeviceModal(false)}
                handleBtnClick = {handleBtnClick}
                title="Add Device"
                submitText = "Add Device"
            >
                <InputDiv>
                <label htmlFor="client_name">Client Name</label>
                <br />
                <Input 
                    id="client_name"
                    value={newDevice.client_name} 
                    placeholder="Enter client name" 
                    onChange={(e) => handleChange("client_name", e.target.value)}
                />
                <br />
                {errors && errors.client_name ? <span className="error">{errors.client_name}</span> : null}
                </InputDiv>

                <InputDiv>
                <label htmlFor="device_name">Device Name</label>
                <br />
                <Input 
                    id="device_name" 
                    value={newDevice.device_name} 
                    placeholder="Enter device name" 
                    onChange={(e) => handleChange("device_name", e.target.value)}
                />
                <br />
                {errors && errors.device_name ? <span className="error">{errors.device_name}</span> : null}
                </InputDiv>

                <InputDiv>
                <label htmlFor="address">Site Address</label>
                <br />
                <Input 
                    id="address"
                    value={newDevice.address} 
                    placeholder="Enter site address" 
                    onChange={(e) => handleChange("address", e.target.value )}
                />
                <br />
                {errors && errors.address ? <span className="error">{errors.address}</span> : null}
                </InputDiv>

                <InputDiv>
                <label htmlFor="device_label">Device Label</label>
                <br />
                <Input 
                    id="device_label"
                    value={newDevice.device_label} 
                    placeholder="Enter device label" 
                    onChange={(e) => handleChange("device_label", e.target.value)}
                />
                <br />
                {errors && errors.device_label ? <span className="error">{errors.device_label}</span> : null}
                </InputDiv>

            </DefaultModal>



        </>
    )
}

// DeviceTable.propTypes = {
//     plants: PropTypes.arrayOf(Object).isRequired,
//     mainStats: PropTypes.instanceOf(Object).isRequired,
// };

export default DeviceTable;
