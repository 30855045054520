import React, { useState } from "react";
import "./leftSidebar.css";
import { NavLink } from "react-router-dom";
import { getUser } from "store/App/AppReducer";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  setAuthState,
  setCompany,
  setPlants,
  setUser,
} from "store/App/AppActions";
import { APP_TOKEN } from "constants/enums/appEnums";
import { NOT_AUTHENTICATED } from "constants/enums/authEnums";

const StyledDropdown = styled.div`
  .down_icon_sidebar img {
    transform: rotate(${(props) => props.rotate});
    transition: transform 0.5s;
  }

  .type-dropdown {
    background-color: transparent;
  }

  .dropdown-list {
    max-height: ${(props) => props.height};
    overflow: hidden;
    transition: max-height 0.5s;
    text-align: center;
  }
`;

const LeftSidebar = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => getUser(state));

  const [dropHeight, setDropHeight] = useState(0);
  const [arrowRotate, setArrowRotate] = useState("0deg");

  const handleDropClick = () => {
    setDropHeight(dropHeight == 0 ? "300px" : 0);
    setArrowRotate(arrowRotate == "0deg" ? "180deg" : "0deg");
  };

  const logOut = () => {
    window.localStorage.removeItem(APP_TOKEN);
    dispatch(setPlants([]));
    dispatch(setCompany(null));
    dispatch(setUser(null));
    dispatch(setAuthState(NOT_AUTHENTICATED));
    window.location = "/login";
  };
  const getShortName = (str) => {
    if (str) {
      const strCopy = typeof str !== "string" ? str.toString() : str;
      const name = strCopy
        .match(/(^\S\S?|\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
      return name;
    }
    return str;
  };
  const options = [
    {
      label: "MC5 Connector",
      value: "MC5 Connector",
    },
    {
      label: "MC4 Connector",
      value: "MC4 Connector",
    },
    {
      label: "MC3 Connector",
      value: "MC3 Connector",
    },
    {
      label: "MC2 Connector",
      value: "MC2 Connector",
    },
  ];
  return (
    <div className="left-side-section">
      <div className="side_bg">
        <img
          src="https://ik.imagekit.io/rzlzf40okya/smarthelio/logo_gray.svg"
          className="logo_im"
        />
        <div className="top_link">
          <li>
            <NavLink to="/">
              <span>
                <img
                  src="https://ik.imagekit.io/rzlzf40okya/smarthelio/home_outline.svg"
                  className="sidebar-link-icon"
                />
              </span>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/fault-classification">
              <span>
                <img
                  src="https://ik.imagekit.io/rzlzf40okya/smarthelio/help_circle_outline.svg"
                  className="sidebar-link-icon"
                />
              </span>
              Fault Classification
            </NavLink>
          </li>

          <li>
            <StyledDropdown height={dropHeight} rotate={arrowRotate}>
              <div onClick={handleDropClick} className="type-dropdown d-flex">
                <div>
                  <span>
                    <img
                      src="https://ik.imagekit.io/rzlzf40okya/smarthelio/grid_big.svg"
                      className="sidebar-link-icon"
                    />
                  </span>
                  <p className="mb-0">Connector Type</p>
                </div>

                <div className="down_icon_sidebar">
                  <img
                    src="https://ik.imagekit.io/rzlzf40okya/smarthelio/chevron_big_down.svg"
                    className="sidebar-link-icon"
                  />
                </div>
              </div>
              <div className="dropdown-list">
                {options.map((item) => {
                  return (
                    <a>
                      <p>{item.label}</p>
                    </a>
                  );
                })}
              </div>
            </StyledDropdown>
          </li>
          <span className="bottom_link">
            {/* <li>
              <NavLink to="/settings">
                <span>
                  <img
                    src="https://ik.imagekit.io/rzlzf40okya/smarthelio/settings.svg"
                    className="sidebar-link-icon"
                  />
                </span>
                Settings
              </NavLink>
            </li> */}
            <li>
              <a onClick={() => logOut()}>
                <span>
                  <img
                    src="https://ik.imagekit.io/rzlzf40okya/smarthelio/log_out.svg"
                    className="sidebar-link-icon"
                  />
                </span>
                <span>Logout</span>
              </a>
            </li>
            <li className="profile_bg">
              <StyledDropdown height={dropHeight} className="logout_dropdown">
                <span className="profile_logo">
                  {getShortName(user?.contact_name)}
                </span>{" "}
                <p className="mb-0">{user?.contact_name}</p>
              </StyledDropdown>
            </li>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;