import React from "react";
import styled from "styled-components";
import moment from "moment";

const StyledDiv = styled.div`
  cursor: pointer;

  &:hover {
    background: #558194;
    color: #ffffff;
  }
`;

const DateButton = ({ filters, setFilters }) => {
  const handleKPIWeekChange = (e) => {
    const newFilters = { ...filters };
    newFilters.start_date = moment().endOf("day").subtract({ day: 7 });
    newFilters.end_date = moment().startOf("day");
    setFilters(newFilters);
  };

  const handleKPIMonthChange = (e) => {
    const newFilters = { ...filters };
    newFilters.end_date = moment().startOf("day").subtract({ day: 1 });
    newFilters.start_date = moment().endOf("day").subtract({ day: 30 });
    setFilters(newFilters);
  };

  return (
    <>
      <StyledDiv
        className="white_button"
        onClick={(e) => handleKPIWeekChange(e)}
      >
        <h4>Week</h4>
      </StyledDiv>
      <StyledDiv
        className="white_button"
        onClick={(e) => handleKPIMonthChange(e)}
      >
        <h4>Month</h4>
      </StyledDiv>
    </>
  );
};

export default DateButton;
