import {
    SET_PREDICTION_DATA,
    SET_PREDICTION_DATA_LOADING,
    SET_PROJECTION_DATA,
    SET_PROJECTION_DATA_LOADING,
    SET_CONNECTOR_DATA,
    SET_CONNECTOR_DATA_LOADING
} from './ConnectorActions';

const initialState = {
    predictionData : null, 
    isPredictionDataLoading : false,
    projectionData : null, 
    isProjectionDataLoading : false,
    ConnectorData : null,
    isConnectorDataLoading : false
}

const connectorReducer = (state = initialState, action={}) =>{
    switch (action.type) {
        case SET_PREDICTION_DATA_LOADING:
            return {
                ...state,
                isPredictionDataLoading: action.status,
            }

        case SET_PREDICTION_DATA :
            return {
                ...state,
                predictionData : action.data
            }
        case SET_PROJECTION_DATA_LOADING:
            return {
                ...state,
                isProjectionDataLoading: action.status,
            }

        case SET_PROJECTION_DATA :
            return {
                ...state,
                projectionData : action.data
            }
        case SET_CONNECTOR_DATA_LOADING:
            return {
                ...state,
                isConnectorDataLoading: action.status,
            }

        case SET_CONNECTOR_DATA :
            return {
                ...state,
                connectorData : action.data
                }
        default:
            return state;
    }
}

/**selectors */
export const getPredictionDataLoaded = state => state.connector.isPredictionDataLoading
export const getPredictionDataResult = state => state.connector.predictionData
export const getProjectionDataLoaded = state => state.connector.isProjectionDataLoading
export const getProjectionDataResult = state => state.connector.projectionData
export const getConnectorDataLoaded = state => state.connector.isConnectorDataLoading
export const getConnectorDataResult = state => state.connector.ConnectorData


export default connectorReducer;
