import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ConnectorMiddleHeader from "components/Connectors/ConnectorMiddleHeader";
// import connectorDevices from "content/connectorDevices.json";
import ConnectorTable from "../../components/ConnectorTable/ConnectorTable";
import "./faultClassification.css";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceInputData, getAllConnectorsDataLoaded  } from "store/App/AppReducer";
import { getAllConnectorsData} from "store/App/AppActions";
import HealthReport from "components/StatusReportForm/Components/HealthReport";
import Sankey from "components/Charts/Sankey/Sankey";
import { RiseLoader } from "react-spinners";
import {getConnectorListLoaded, getConnectorListResult } from "store/FaultClassificationPage/FaultClassificationReducer";
import { getConnectorList } from "store/FaultClassificationPage/FaultClassificationActions";
import { getDashboardDataResult } from "store/Dashboard/DashboardReducer";
import moment from 'moment'
import { classificationConstants } from "constants/enums/constant"; 

const faultMapper = {
  "normal" : 1,
  "faulty": 2,
  "hazardous": 3,
};

const FaultClassification = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sankeyData, setSankeyData] = useState(null)

  //from dashbord reducer
  const dashboardData = useSelector(state => getDashboardDataResult(state))
  //from faultclassification reducer
  const connectorList = useSelector(state => getConnectorListResult(state))
  const isConnectorListLoaded = useSelector(state => getConnectorListLoaded(state))

  const getConnectorStateTransitionCount = () => {
    // TODO move to a constants file for reuse at multiple places
    const faultStatuses = ["normal", "faulty", "hazardous"]
    const stateTransitionMapper = {
      // normal -> [normal, faulty, hazardous]
      // faulty -> [faulty, hazardous]
      // hazardous -> [hazardous]
    };
    for (let i in faultStatuses) {
      const initialFaultStatus = faultStatuses[i];
      stateTransitionMapper[initialFaultStatus] = {};
      for (let j in faultStatuses) {
        if (j >= i) {
          const finalFaultStatus = faultStatuses[j];
          if(initialFaultStatus && finalFaultStatus){
            stateTransitionMapper[initialFaultStatus][finalFaultStatus] = 0;
          }
        }
      }
    }

    console.log("state transition mapper", stateTransitionMapper)

      connectorList.map(({current_status, last_fault_status}) => {
        stateTransitionMapper[current_status][last_fault_status]++
      })

    return stateTransitionMapper
  }

  const getInitialConnectorCount = (status) => dashboardData?.status_counts.filter(({_id}) => _id?.current_status == status)[0]?.count;
  const getFinalConnectorCount = (status) => dashboardData?.year_end_status_counts.filter(({_id}) => _id?.last_fault_status == status)[0]?.count;

  const getTransitions = (numTransitions) => {
    let transitions = []

    for(let initial in numTransitions){
      for(let final in numTransitions[initial]){
        if(numTransitions[initial][final] !== 0) {
          transitions.push([classificationConstants[`${initial}_initial`], classificationConstants[`${final}_end`], numTransitions[initial][final], classificationConstants[`color_${initial}`]])
        }
      }
    }

    return transitions
  }

  const calculateSankeyData = () => {
    const numTransitions =  getConnectorStateTransitionCount()
    // console.log(numTransitions, dashboardData, connectorList, "ALL data required")
    let date = moment()

    const data = {
      nodes : [
        {"name":classificationConstants.hazardous_initial, "color" : "#F04B4B", value : getInitialConnectorCount("hazardous") ? getInitialConnectorCount("hazardous") : 0, type : "Hazardous"},
        {"name":classificationConstants.faulty_initial, "color" : "#F2A541", value : getInitialConnectorCount("faulty") ? getInitialConnectorCount("faulty") : 0, type : "Faulty"},
        {"name":classificationConstants.normal_initial, "color" : "#60C610", value : getInitialConnectorCount("normal") ? getInitialConnectorCount("normal") : 0, type : "Normal"},
        {"name":classificationConstants.hazardous_end, "color" : "#F04B4B", value : getFinalConnectorCount("hazardous") ? getFinalConnectorCount("hazardous") : 0, type : "Hazardous"},
        {"name":classificationConstants.faulty_end, "color" : "#F2A541", value : getFinalConnectorCount("faulty") ? getFinalConnectorCount("faulty") : 0, type : "Faulty"},
        {"name":classificationConstants.normal_end, "color" : "#60C610", value : getFinalConnectorCount("normal") ? getFinalConnectorCount("normal") : 0, type : "Normal"},
        ],
      links : [
        {
          datetime : moment(date).subtract({ day: 0 }).format("DD-MM-YYYY"),
          transactions : getTransitions(numTransitions)
        },
        {
        datetime : moment(date).add({ day: 365 }).format("DD-MM-YYYY"),
        transactions : []
        },
      ]}

      setSankeyData(data)
  }

  useEffect(() => {
    if(connectorList){
      calculateSankeyData()
      localStorage.setItem("connector-list", JSON.stringify(connectorList))
    }
  }, [connectorList])
  console.log(sankeyData, "Sankey Data")
  useEffect(() => {
    if(!connectorList){
      dispatch(getConnectorList())
    }
  },[])

  const handleSelectTab = (value) => {
    setSelectedTab(value);
  };


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  const parsedURL = new URLSearchParams(location.search);
  const redirectToFaulty = parsedURL.has("faulty");
  const redirectToHazardous = parsedURL.has("hazardous");

  const getRedirectionFault = () => {
    if (redirectToFaulty) {
      return 2;
    }
    if (redirectToHazardous) {
      return 3;
    }
    return 1;
  }

  useEffect(() => {
    handleSelectTab(getRedirectionFault());
  }, []);

  const handleTabChange = (val) => {
      setSelectedTab(val);
  };
  console.log(connectorList, isConnectorListLoaded, "devices")

  return (
    <>
      <h2 className="fault_heading">Fault Classification</h2>

      <div className="d-flex">
        <div className="left_graph">
          <div className="white_box" style={{ height: "400px", padding:"10px" }}>
            <div style={{height : "100%"}}>
                {loading ?
                <div style={{height : "100%", display:"flex", justifyContent :"center", alignItems:"center"}}>
                  <RiseLoader size={8} color={"#558194"} />
                </div>
                :
                sankeyData && <Sankey data = {sankeyData}/>
                }
            </div>
          </div>
        </div>
      </div>
      <ConnectorMiddleHeader
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      {isConnectorListLoaded && (
        <ConnectorTable
          faultMapper={faultMapper}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          connectorDevices={connectorList}
        />
      )}
      <div style={{width:"100%"}}>
      <div className="health_card">
        <HealthReport selectedReportType={selectedTab} />
      </div>
      </div>
    </>
  );
};

export default FaultClassification;
