import {
  AUTHENTICATED,
  AUTHENTICATNG,
  NOT_AUTHENTICATED,
} from "constants/enums/authEnums";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import ConnectorsListPage from "../pages/ConnectorsListPage/ConnectorsListPage";
import DeviceInfoPage from "../pages/DeviceInfoPage/DeviceInfoPage";
import DevicesListPage from "../pages/DevicesListPage/DevicesListPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage/ForgotPasswordPage";
import FullPageLoader from "../components/FullPageLoader/FullPageLoader";
import LoginPage from "../pages/LoginPage/LoginPage";
import NavbarSidebarGlobal from "../components/NavbarSidebarGLobal/NavbarSidebarGlobal";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import React from "react";
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage";
import UploadPage from "../pages/UploadPage/UploadPage";
import UserListPage from "../pages/UserListPage/UserListPage";
import { getAuthenticatedState } from "store/App/AppReducer";
import { useSelector } from "react-redux";
import FaultClassification from "../pages/FaulClassification/FaultClassification.js";

// Authenticated Pages
const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <NavbarSidebarGlobal>
            <Outlet />
          </NavbarSidebarGlobal>
        }
      >
        <Route index element={<ConnectorsListPage />} />
        <Route path="fault-classification" element={<FaultClassification />} />
        <Route path="connectors" element={<ConnectorsListPage />} />
        <Route path="users" element={<UserListPage />} />
        <Route path="devices" element={<DevicesListPage />} />
        <Route path={"device"}>
          <Route path=":id/view" element={<DeviceInfoPage />} />
        </Route>
      </Route>
      <Route path="/upload" element={<UploadPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/login" element={<Navigate to="/" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const NotAuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

function AppRoutes() {
  const authState = useSelector((state) => getAuthenticatedState(state));

  const getRoutes = () => {
    switch (authState) {
      case AUTHENTICATED:
        return <AuthenticatedRoutes />;
      case NOT_AUTHENTICATED:
        return <NotAuthenticatedRoutes />;
      case AUTHENTICATNG:
        return <FullPageLoader />;
      default:
        return <FullPageLoader />;
    }
  };

  return <>{getRoutes()}</>;
}

export default AppRoutes;
