import callApi, { LambdaCallApi } from "../../util/apiCaller";
import { errorMsg, successMsg } from "util/toast";

export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA"
export const SET_DASHBOARD_DATA_LOADING = "SET_DASHBOARD_DATA_LOADING"
export const SET_CONNECTOR_TYPE = "SET_CONNECTOR_TYPE"
export const SET_CONNECTOR_TYPE_LOADING = "SET_CONNECTOR_TYPE_LOADING"
export const SET_CHANGED_CONNECTOR = "SET_CHANGED_CONNECTOR"
export const SET_STATE_CHANGE_LOADING = "SET_STATE_CHANGE_LOADING"


export const setDashboardData = (data) => ({
  type : SET_DASHBOARD_DATA,
  data
})

export const setIsDashboardDataLoading = (status) => ({
  type : SET_DASHBOARD_DATA_LOADING,
  status
})

export const setConnectorType = (data) => ({
  type : SET_CONNECTOR_TYPE,
  data
})

export const setIsConnectorTypeLoading = (status) => ({
  type : SET_CONNECTOR_TYPE_LOADING,
  status
})

export const setChangedConnector = (data) => ({
  type : SET_CHANGED_CONNECTOR,
  data
})

export const setIsStateChangeLoading = (status) => ({
  type : SET_STATE_CHANGE_LOADING,
  status
})


export function getDashboardData() {
  return async (dispatch) => {
    try {
      dispatch(setIsDashboardDataLoading(true));
      const res = await callApi("api/results/dashboard", "get");

      if (res && res.status === "Success") {
        dispatch(setDashboardData(res?.data));
        dispatch(setIsDashboardDataLoading(false));

      } else {
        errorMsg("Dashboard data not loaded!");
      } 
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsDashboardDataLoading(false));
    }
  };
}

export function getConnectorTypeList() {
  return async (dispatch) => {
    try {
      dispatch(setIsConnectorTypeLoading(true));
      const res = await callApi("api/results/connector-types/list", "post", {
        pageSize : 1000,
        pageNum : 1
      });

      if (res && res.status === "Success") {
        dispatch(setConnectorType(res?.data));
        dispatch(setIsConnectorTypeLoading(false));

      } else {
        errorMsg("Connector Type List not loaded!");
      } 
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsConnectorTypeLoading(false));
    }
  };
}

export function stateChange(id) {
  return async (dispatch) => {
    try {
      dispatch(setIsStateChangeLoading(true));
      const res = await callApi(`api/results/connector/${id}/state-change`, "post", {
        connector : {
          "company_id":  "",
          "device_id" : "",
          "fault_status" : "Faulty"
      }
      });

      if (res && res.status === "Success") {
        dispatch(setChangedConnector(res?.data));
        dispatch(setIsStateChangeLoading(false));

      } else {
        errorMsg("Connector Type List not loaded!");
      } 
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsStateChangeLoading(false));
    }
  };
}


