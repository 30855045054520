import React, {useEffect, useState} from 'react';

import {CircularProgress} from "@mui/material";
import {Helmet} from "react-helmet";
import UserTable from '../../components/UserTable/UserTable';
import callApi from "../../util/apiCaller";
import Tables from 'components/Tables/Tables';
const UserListPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [columnItems, setColumnItems] = useState({})
    console.log('userdataalt==>',users)
  
    

   

    const getHeadings = (firstElement) => {
        console.log("dvcsa", firstElement)
        setColumnItems(Object.keys(firstElement))
    }
    
    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            const res = await callApi(`users/list`, 'post', {
                pageSize: 500
            });
            if (res && res.data) {
                const {list} = res.data;
                getHeadings(list[0])
                setUsers(list);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        fetchUsers();
    }, []);
    console.log("columns", columnItems)
    return (
       
            <div className='row portfolio-row'>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Users | IOT Platform</title>
                </Helmet>
                {/* <div style={{marginTop: "100px"}}></div> */}
                {isLoading ?
                    <div className='row reports-content-row'>
                        <div>
                            <CircularProgress/>
                        </div>
                    </div> :
                    null
                }
                {!isLoading && users.length > 0 && (

                    <UserTable
                        users={users}
                        fetchUsers={fetchUsers}
                    />
                  

                )}
            </div>
    
    )
}

export default UserListPage;
