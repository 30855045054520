import React from 'react'
import { sankeyLinkHorizontal, SankeyLinkMinimal } from 'd3-sankey'
import { useEffect } from 'react'
import * as d3 from "d3"

const SankeyLink = ({link, svgWidth}) => {

useEffect(() => {
    let path = d3.selectAll(".linked")
    let pathLength = path.node().getTotalLength()

    path
    .attr("stroke-dashoffset", svgWidth)
    .attr("stroke-dasharray", svgWidth)
    .transition()
    // .delay(combinedData.length*timeConstant + greenLine.length*timeConstant + orangeLine.length*timeConstant)
    .duration(2000)
    .attr("stroke-dashoffset", 0)
},[])

    return <path 
        d = {sankeyLinkHorizontal()(link)}
        fill="lightgray"
        className="linked"
        style={{
            stroke : link.color,
            strokeOpacity : .5, 
            strokeWidth : Math.max(1, link.width),
            mixBlendMode : "multiply",
            fill : "none"
        }}
    />
    }
export default SankeyLink