import React from "react";
import Box from "konnect/Box";
import "./connectors.css";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(45, 35, 46, 0.65);
  font-size: 1.2vw
}
span{
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out; 
}
  &:hover{
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out; 
  }
`;

const ConnectorMiddleHeader = ({ selectedTab, handleTabChange }) => {
  return (
    <div className="middleContainer">
      <Box className="d-flex justify-content-between" gridGap="6px" padding="4px">
        <StyledDiv
          onClick={() => handleTabChange(1)}
          className={selectedTab === 1 ? "sb-active" : "sub-header-value"}
        >
          <span>Normal</span>
        </StyledDiv>
        <StyledDiv
          onClick={() => handleTabChange(2)}
          className={selectedTab === 2 ? "sb-active" : "sub-header-value"}
        >
         <span>Faulty</span>
        </StyledDiv>
        <StyledDiv
          onClick={() => handleTabChange(3)}
          className={selectedTab === 3 ? "sb-active" : "sub-header-value"}
        >
          <span>Hazardous</span>
        </StyledDiv>
        {/* <StyledDiv
          onClick={() => handleTabChange(4)}
          className={selectedTab === 4 ? "sb-active" : "sub-header-value"}
        >
          <span>crossmated</span>
        </StyledDiv>
        <StyledDiv
          onClick={() => handleTabChange(5)}
          className={selectedTab === 5 ? "sb-active" : "sub-header-value"}
        >
          <span>bad crimp</span>
        </StyledDiv> */}
      </Box>
    </div>
  );
};

export default ConnectorMiddleHeader;
