export function GradientColorGenerater(svg, colors) {

    let useColors = []
    for(let color of colors){
        useColors.push({
            color,
            percent : 1
        })
    }

    console.log("useCOlors", useColors)
    if (!svg) return;
    let defs = svg.append("svg:defs");
    let res = [];
    if (Array.isArray(useColors)) {
        useColors.forEach(function (item, index) {
            let next = useColors[index + 1] || useColors[0];
            let id = `color_gradient_${index}`;
            let gradient = defs.append("linearGradient")
                .attr("id", id);

            gradient.append("stop")
                .attr("offset", "0%")
                .attr("stop-color", item.color);

            gradient.append("stop")
                .attr("offset", "100%")
                .attr("stop-color", next.color);

            res[index] = {
                gradient:gradient,
                color: `url(#${id})`,
                percent: item.percent
            }
        });
    }
    return res;
}

export function dateDiffCalculator(previousDate, newDate){
    const DAY_UNIT_IN_MILLISECONDS = 24 * 3600 * 1000
    let difference = (newDate.getTime() - new Date(previousDate).getTime())/DAY_UNIT_IN_MILLISECONDS

    return difference
}