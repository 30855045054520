import callApi, { LambdaCallApi } from "../../util/apiCaller";
import { errorMsg, successMsg } from "util/toast";

import { APP_TOKEN } from "constants/enums/appEnums";
import { AUTHENTICATED } from "constants/enums/authEnums";
import jwt_decode from "jwt-decode";
import plantMapping from "util/plantUtil";
import moment from "moment";
import connectorDevices from "../../content/connectorDevices.json";

export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_COMPANY = "SET_COMPANY";
export const SET_USER = "SET_USER";
export const SET_PLANTS = "SET_PLANTS";
export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SET_IS_LOGIN_LOADING = "SET_IS_LOGIN_LOADING";
export const SET_DEVICE_INPUT_DATA = "SET_DEVICE_INPUT_DATA";
export const SET_CONN_DATA_LOADED = "SET_CONN_DATA_LOADED";

export const setAuthState = (authState) => ({
  type: SET_AUTH_STATE,
  authState,
});

export const setIsLoginLoading = (isLoginLoading) => ({
  type: SET_IS_LOGIN_LOADING,
  isLoginLoading,
});

export const setIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  isLoading,
});

export const setCompany = (company) => ({
  type: SET_COMPANY,
  company,
});

export const setPlants = (plants) => ({
  type: SET_PLANTS,
  plants,
});

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const setDeviceInputs = (deviceInputData) => ({
  type: SET_DEVICE_INPUT_DATA,
  deviceInputData,
});

export const setConnectorDataLoaded = (status) => ({
  type: SET_CONN_DATA_LOADED,
  status,
});

export function getPlants(filters) {
  return async (dispatch) => {
    try {
      const res = await LambdaCallApi(`plant/filter?${filters}`, "get", null);
      if (res && res.statusCode === 200) {
        res.body.records.map((record) => {
          const newRecord = { ...record };
          newRecord.plant_id_dummy = plantMapping(newRecord.plant_id);
          newRecord.plant_address = "";
          newRecord.label = newRecord.plant_id_fk;
          newRecord.value = newRecord.plant_id.toString();
          return newRecord;
        });
        dispatch(setPlants(res.body.records));
      }
    } catch (err) {
      // do something here like show error toast or loading false
    }
  };
}

export function getCompanyDetails(filters) {
  return async (dispatch) => {
    try {
      const res = await LambdaCallApi(`company/filter?${filters}`, "get", null);
      if (res && res.statusCode === 200) {
        dispatch(setCompany(res.body.records[0] ?? null));
      }
    } catch (err) {
      // do something here like show error toast or loading false
    }
  };
}

export function loginUser(creds) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoginLoading(true));
      const res = await callApi("auth/user/login", "post", creds);

      dispatch(setIsLoginLoading(false));
      if (res && res.status === "Success") {
        const { token } = res.data;
        window.localStorage.setItem(APP_TOKEN, token);
        const tempUser = jwt_decode(token);
        dispatch(setUser(tempUser));
        dispatch(setAuthState(AUTHENTICATED));
        successMsg("Welcome");
      } else {
        errorMsg("Credential are not valid....!");
      }
    } catch (err) {
      errorMsg("Something went wrong..!");
      dispatch(setIsLoginLoading(false));
    }
  };
}

export function getAllConnectorsData() {
  let fetchPageSize = 10000;

  //TODO : Discuss if there is need for filter in fault classification to get data
  const filters = {
    end_date: moment().startOf("day").subtract({ day: 1 }),
    start_date: moment().endOf("day").subtract({ day: 30 }),
  };

  return async (dispatch) => {
    try {
      for (let connector of connectorDevices) {
        let totalRecordsCount = await callApi(
          `deviceinputdatas/list?device_id=${connector?.device_id}`,
          "post",
          {
            filters: {
              start_date: filters.start_date,
              end_date: filters.end_date,
            },
            pageNum: 1,
            pageSize: fetchPageSize,
          }
        )
          .then((res) => res.data.count)
          .catch((err) => console.log(err));

        console.log("total records count mppt", totalRecordsCount);
        for (let i = 1; i <= totalRecordsCount / fetchPageSize + 1; i++) {
          const res = await callApi(
            `deviceinputdatas/list?device_id=${connector?.device_id}`,
            "post",
            {
              filters: {
                start_date: filters.start_date,
                end_date: filters.end_date,
              },
              pageNum: i,
              pageSize: fetchPageSize,
            }
          )
            .then((res) => {
              dispatch(setDeviceInputs(res.data.list));
            })
            .catch((err) => console.log(err));
        }
      }
      dispatch(setConnectorDataLoaded(true));
    } catch (err) {
      // do something here like show error toast or loading false
    }
  };
}
