import Footer from "components/Footer/Footer";
import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import LeftSidebar from "components/LeftSidebar.js/LeftSidebar";

const NavbarSidebarGlobal = ({ children }) => {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <div className="container-fluid">
        <LeftSidebar />
        <div className="right_side_section">{children}</div>
      </div>
      {/* {id ? null : <Footer />} */}
      <Footer />
    </>
  );
};

NavbarSidebarGlobal.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NavbarSidebarGlobal;
