import './ConnectorTable.css';
import React, {useState} from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import DefaultModal from 'components/DefaultModal/DefaultModal';
import PropTypes from 'prop-types';
import callApi from 'util/apiCaller';
import { errorMsg, successMsg } from 'util/toast';
import Input from 'konnect/Input/Input';
import Tables from 'components/Tables/Tables';

const InputDiv = styled.div`

    margin-bottom : 5px;

    .error { 
        color : red;
        font-size: 12px
    }

    label {
        font-size : 10px;
        margin-bottom : 4px;
    }
`

const options = {
    chart: {
        height: 700,
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        width: 2,
        dashArray: 0,
    },
    title: {
        text: 'Projected Loss Graph',
        align: 'left'
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        title: {
            text: 'Date'
        }
    },
    yaxis: {
        title: {
            text: 'Cumulative Projected Loss (in CHF)'
        },
    },
};

const UserTable = ({ users, fetchUsers }) => {

    const columnsItems=[
        {field:'username',header:'user name'},
        {field:'emailaddress',header:'email address'},
        {field:'companyname',header:'company name'},
        {field:'userrole',header:'user role'},
    ]

    const rowsItems =[
        {username:'Christian Gysin',emailaddress:'c.gysin@staubli.com',companyname:'SmartHelio',userrole:'Admin'},
        {username:'Christian Gysin',emailaddress:'c.gysin@staubli.com',companyname:'SmartHelio',userrole:'Admin'},
        {username:'Christian Gysin',emailaddress:'c.gysin@staubli.com',companyname:'SmartHelio',userrole:'Admin'},
        {username:'Christian Gysin',emailaddress:'c.gysin@staubli.com',companyname:'SmartHelio',userrole:'Admin'},
        {username:'Christian Gysin',emailaddress:'c.gysin@staubli.com',companyname:'SmartHelio',userrole:'Admin'},
        {username:'Christian Gysin',emailaddress:'c.gysin@staubli.com',companyname:'SmartHelio',userrole:'Admin'},
    ]

    const [newUser, setNewUser] = useState({
        first_name : "",
        last_name : "",
        email : "",
        company_name : ""
    })
    const [errors, setErrors] = useState({})
    const [addUserModal, setAddUserModal] = useState(false);
    const openAddUserModal = () => setAddUserModal(true);
    const closeAddUserModal = () => {
        setAddUserModal(false);
        setNewUser({})
        setErrors({})
    };

    const handleChange = (keyName, value) => {
        let update = {...newUser}
    
        update[keyName] = value
    
        setNewUser(update)
    }

    const handleValidation = () => {
        let re = /\S+@\S+\.\S+/

        let isValid = true;
        const newErrors = {};
        if (!newUser.first_name) {
            isValid = false;
            newErrors.first_name = 'Please enter first name';
        }
        if (!newUser.last_name) {
            isValid = false;
            newErrors.last_name = 'Please enter last name';
        }
        if (!newUser.email) {
            isValid = false;
            newErrors.email = 'Please enter email';
        } else if(!re.test(newUser.email)) {
            isValid = false;
            newErrors.email = 'Please enter valid email';
        }
        if (!newUser.company_name) {
            isValid = false;
            newErrors.company_name = 'Please enter company name';
        }
        return {
            isValid,
            errors: newErrors
        }
    };

    const handleBtnClick = (type) => {

        if(type == "right"){
            const check = handleValidation();

            if(check.isValid){
                successMsg("User added successfully!")
                setAddUserModal(false);
                setNewUser({})
                setErrors({})
                //let User = {
                    // username : newUser.first_name + newUser.last_name, 
                    // email : newUser.email,
                    // company_name : newUser.company_name
                // }
                //Todo : Add end point
                // callApi("", "post", {
                //     user : User
                // })
                // .then(res => {
                //     if(res.status == "Success"){
                //         successMsg("User added successfully!")
                //         fetchUsers()
                //     }
                // })
                // .catch(err => {
                //     errorMsg("Something went wrong")
                //     console.log(err)
                // })
            } else {
                setErrors(check.errors)
            }    
        } else {
            setAddUserModal(false);
            setNewUser({})
            setErrors({})
        }
       
    }
    
    return (
        <>
            <div className='connecter-box'>
                <p>Users</p>
            </div>
            <div className='col-lg-12 reports-content'>
                <div className='row reports-content-row'>
                    {users.length ? (
                        <div className='reports-content-box' style={{ textAlign: 'left' }}>
                            {/* <table className="table table-hover mobile-none">
                                <thead>
                                    <tr>
                                        <th scope="col" className='table-heading'>User Name</th>
                                        <th scope="col" className='table-heading'>Email Address
                                        </th>
                                        <th scope="col" className='table-heading'>{'Company Name'}</th>
                                        <th scope='col' className='table-heading'>
                                            User Role
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users?.map((plant ) => (
                                        <tr
                                            // style={{textAlign: 'center'}}
                                            key={plant.contact_name}
                                        >
                                            <td>
                                                {plant.contact_name}
                                            </td>
                                            <td>
                                                {plant.contact_email}
                                            </td>
                                            <td>
                                                {plant.company_name ? plant.company_name : "SmartHelio"}
                                            </td>
                                            <td>
                                                {plant.role ? plant.role : "Admin"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> */}
                            <Tables columnsData={columnsItems} rowData={rowsItems}/>
                        </div>
                    ) : (
                        <div>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
            <div className='add-device-btn'>
                <button type="button" onClick={openAddUserModal}>ADD USER</button>
            </div>
           
            <DefaultModal
                show={addUserModal}
                onHide={() => closeAddUserModal(false)}
                handleBtnClick = {handleBtnClick}
                title="Add User"
                submitText = "ADD USER"
            >
                <InputDiv>
                <label htmlFor="first_name">First Name</label>
                <br />
                <Input 
                    id="first_name"
                    value={newUser.first_name} 
                    placeholder="e.g. John" 
                    onChange={(e) => handleChange("first_name", e.target.value)}
                />
                <br />
                {errors && errors.first_name ? <span className="error">{errors.first_name}</span> : null}
                </InputDiv>

                <InputDiv>
                <label htmlFor="last_name">Last Name</label>
                <br />
                <Input 
                    id="last_name"
                    value={newUser.last_name} 
                    placeholder="e.g. doe" 
                    onChange={(e) => handleChange("last_name", e.target.value)}
                />
                <br />
                {errors && errors.last_name ? <span className="error">{errors.last_name}</span> : null}
                </InputDiv>

                <InputDiv>
                <label htmlFor="email">Email ID</label>
                <br />
                <Input 
                    id="email"
                    value={newUser.email} 
                    placeholder="e.g. abc@xyz.com" 
                    onChange={(e) => handleChange("email", e.target.value)}
                />
                <br />
                {errors && errors.email ? <span className="error">{errors.email}</span> : null}
                </InputDiv>

                <InputDiv>
                <label htmlFor="company">Company Name</label>
                <br />
                <Input 
                    id="company"
                    value={newUser.company_name} 
                    placeholder="e.g. ABC Company" 
                    onChange={(e) => handleChange("company_name", e.target.value)}
                />
                <br />
                {errors && errors.company_name ? <span className="error">{errors.company_name}</span> : null}
                </InputDiv>

            </DefaultModal>
        </>
    )
}

// UserTable.propTypes = {
//     plants: PropTypes.arrayOf(Object).isRequired,
//     mainStats: PropTypes.instanceOf(Object).isRequired,
// };

export default UserTable;
